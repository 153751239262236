import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "Turkey will join the EU",
  "results": {
    "deal": {
      "status": "green",
      "comment": "Turkey is not likely to join the EU so leaving or remaining won't make any difference"
    },
    "disorderly": {
      "status": "green",
      "comment": "Turkey is not likely to join the EU so leaving or remaining won't make any difference"
    },
    "remain": {
      "status": "green",
      "comment": "Turkey is not likely to join the EU so leaving or remaining won't make any difference"
    },
    "reform": {
      "status": "green",
      "comment": "Turkey is not likely to join the EU so leaving or remaining won't make any difference"
    }
  }
};

const LeaveTurkey = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>Although Turkey has expressed wishes to join the EU in the past, there is currently no progress being made. Talks have now been stalled for years. If they started again and progress was made, and Turkey became eligible for EU membership (it is currently nowhere near being compliant) the UK could still veto this if it wanted. (unless we leave)</p>
    <p>However unlikely Turkey joining the EU may be, if they were to join and many people headed to the UK, we would be able to repatriate anyone who doesn't find work within 6 months and have the ability to restrict available benefits. But again, Turkey's future in the EU is currently unlikely. <OutboundLink href="https://www.bbc.co.uk/news/world-europe-42586108" target="_blank">(source)</OutboundLink>.</p>
  </Topic>
);

export default LeaveTurkey;
