import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "We will limit immigration",
  "results": {
    "deal": {
      "status": "green",
      "comment": "We can choose to reduce immigration if we wish"
    },
    "disorderly": {
      "status": "green",
      "comment": "We can choose to reduce immigration if we wish"
    },
    "remain": {
      "status": "green",
      "comment": "We can choose to reduce immigration if we wish"
    },
    "reform": {
      "status": "green",
      "comment": "We can choose to reduce immigration if we wish"
    }
  }
};

const LeaveImmigration = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>The UK has the ability to implement more immigration control than it currently does. We already have the option of exit checks at air and sea ports, but do not use them. We retain the right to repatriate EU migrants who have not found a job after 6 months, but we do not have a system for this <OutboundLink href="https://www.theguardian.com/commentisfree/2017/jul/31/britain-take-back-control-immigration-eu-directive-brexit">(source)</OutboundLink>.</p>
    <p>Replacing freedom of movement with a merit based system could lead to a labour shortage for many jobs. Initially, in a no deal scenario, the transition period would likely lead to a shortage across all sectors as employment is the UK is currently at a high. Following the implementation of a merit based system, we would only see enough immigration from workers with specific skills, but sectors such as hospitality and agriculture could see serious staff shortages. It has been implied that the UK could prevent workers from coming here unless they earn £30k per year, leaving jobs that pay lower unfilled.<OutboundLink href="https://www.ft.com/content/36baacce-ddd0-11e8-9f04-38d397e6661c">(source)</OutboundLink>.</p>
    <p>The UK is currently able to control non-EU immigration as it sees fit, without restriction by the European Union</p>
  </Topic>
);

export default LeaveImmigration;
