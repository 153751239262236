import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "There will be no change to the Irish border",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "There is currently no agreed long term solution to the border issue"
    },
    "disorderly": {
      "status": "red",
      "comment": "A no deal brexit could mean a hard border in Ireland"
    },
    "remain": {
      "status": "green",
      "comment": "No border issue as both countries within the EU"
    },
    "reform": {
      "status": "green",
      "comment": "No border issue as both countries within the EU"
    }
  }
};

const LeaveIreland = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>Under the terms of the Withdrawal Agreement we would avoid a hard border by temporarily staying inside a customs union with the EU. However there is no border-free solution agreed meaning that the only way to fully leave the EU after the transition period and avoid a hard border within Ireland would be to implement checks between NI and the rest of the UK. (This is why the DUP will not back the deal as they don't want NI to be treated differently to the rest of the UK)</p>
    <p>If we leave with no deal, we may have to implement a hard border between the Republic of Ireland and Northern Ireland instead. This is because there needs to be a customs border for goods entering and leaving the EU. How this would be implemented is not yet known.</p>
    <p>Either way, this will be a huge change to the current arrangement of no border.</p>
  </Topic>
);

export default LeaveIreland;
