import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "More money to the NHS",
  "results": {
    "deal": {
      "status": "red",
      "comment": "A weaker economy will mean less money to the NHS"
    },
    "disorderly": {
      "status": "red",
      "comment": "A weaker economy will mean less money to the NHS"
    },
    "remain": {
      "status": "green",
      "comment": "Financially, we are better off inside the EU so have more money for the NHS"
    },
    "reform": {
      "status": "green",
      "comment": "Financially, we are better off inside the EU so have more money for the NHS"
    }
  }
};

const LeaveMoney = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>We don't send £350m to the EU each week so we can't send it to the NHS when we leave. It sounds great in theory to spend the money we do pay to the EU on the NHS instead, but in reality, leaving will have such a negative impact on our economy that we will have even less money to spend on the NHS than we do now.<OutboundLink href="https://fullfact.org/europe/does-brexit-dividend-exist/">(source)</OutboundLink></p>
  </Topic>
);

export default LeaveMoney;
