import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "Energy bills will be cheaper",
  "results": {
    "deal": {
      "status": "red",
      "comment": "Brexit will cause energy bills to increase"
    },
    "disorderly": {
      "status": "red",
      "comment": "Brexit will cause energy bills to increase"
    },
    "remain": {
      "status": "green",
      "comment": "A strong Pound and no cross-border tariffs keep prices down"
    },
    "reform": {
      "status": "green",
      "comment": "A strong Pound and no cross-border tariffs keep prices down"
    }
  }
};

const LeaveEnergyBills = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>The Leave campaign claimed that we would be able to lower energy bills if we leave the EU. The theory behind that was we would be able to lower the 5% minimum VAT on energy bills if we leave the EU. The problem with this is that Brexit will cause energy prices to increase above that 5% reduction. In fact Ofgem says average households have already seen an increase of £75 per home for energy bills due to the fall in the value of the pound caused by Brexit and when we actually leave the price increases will be even higher. <OutboundLink href="https://www.independent.co.uk/news/uk/home-news/brexit-energy-bills-gas-electricity-exchange-rates-gbp-euro-ucl-ofgem-a8675211.html" target="_blank">(source)</OutboundLink>.</p>
  </Topic>
);

export default LeaveEnergyBills;
