import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LeaveIcon } from '../assets/bus.svg';

const topic = {
  "title": "We will be able to negotiate our own trade deals",
  "results": {
    "deal": {
      "status": "blue",
      "comment": "We will only be able to negotiate trade deals without the EU if we leave the customs union"
    },
    "disorderly": {
      "status": "green",
      "comment": "We will be able to negotiate trade deals without the EU"
    },
    "remain": {
      "status": "red",
      "comment": "We must negotiate trade deals as part of the EU"
    },
    "reform": {
      "status": "red",
      "comment": "We must negotiate trade deals as part of the EU"
    }
  }
};

const LeaveTradeDeals = () => (
  <Topic topic={topic} icon={LeaveIcon}>
    <p>We currently have many trade deals, negotiated as part of the EU. As the EU is such a big market it receives very favourable trade deals. All of which will have to be renegotiated if we leave. So far we haven't been able to renegotiate them so there will at least be a transition period where we cannot take advantage of the current deals we have. When we eventually renegotiate our trade deals we are unlikely to get as good an offer as we already have now as we are a smaller market. We may also have to drop our standards in order to get better deals, such as chrlorinated chicken from the US. <OutboundLink href="https://www.thetimes.co.uk/article/us-aims-to-include-chlorinated-chicken-in-uk-trade-deal-jdpt5hc5c" target="_blank">(source)</OutboundLink>.</p>
    <p>The bottom line is that we will be able to negotiate our own deals if we leave, this promise was correct. Whether or not it is beneficial is arguable. Under the terms of Theresa May's deal, we would have to wait until we left the customs union before being able to trade using our own deals, and this is uncertain.</p>
  </Topic>
);

export default LeaveTradeDeals;
