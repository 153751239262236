import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import LeaveMoney from '../topics/leave-money';
import LeaveImmigration from '../topics/leave-immigration';
import LeaveIreland from '../topics/leave-ireland';
import LeaveBuisness from '../topics/leave-buisness';
import LeaveTurkey from '../topics/leave-turkey';
import LeaveEnergyBills from '../topics/leave-energy-bills';
import LeaveTradeDeals from '../topics/leave-trade-deals';


import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const Labour = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Leave Promises" shareImage="leave" description="See how various Brexits satisfy Leave promises" />
    <IntroText>
      We've studied Theresa May's withdrawal agreement to see how it compares to a no deal Brexit or remaining in the EU. See below how each deal satisfies Leave's promises.
    </IntroText>
    <TopicHeader title="What were the main Leave promises?" />
    <DealHeadersRow />
    <LeaveMoney />
    <LeaveImmigration />
    <LeaveIreland />
    <LeaveBuisness />
    <LeaveTurkey />
    <LeaveEnergyBills />
    <LeaveTradeDeals />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

Labour.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Labour.defaultProps = {
  data: {},
};

export default withRoot(Labour);
